@include font-face("PaytoneOne-Regular", "PaytoneOne-Regular", 400);
@include font-face("Roboto-Black", "Roboto-Black", 400);
@include font-face("Roboto-Bold", "Roboto-Bold", 400);
@include font-face("Roboto-Medium", "Roboto-Medium", 400);
@include font-face("RobotoCondensed-Bold", "RobotoCondensed-Bold", 400);
@include font-face("RobotoFlex-Regular", "RobotoFlex-Regular", 400);
@include font-face("Rubik-Bold", "Rubik-Bold", 400);
@include font-face("Rubik-Medium", "Rubik-Medium", 400);
@include font-face("RubikGlitch-Regular", "RubikGlitch-Regular", 400);
@include font-face("RussoOne-Regular", "RussoOne-Regular", 400);
