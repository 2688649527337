.mini-header {
    display: flex;
    width: 100%;
    margin: 0 auto;
    background-color: #2a2929;
    border-right: 4px solid #000;
    border-left: 4px solid #000;
    max-width: 1430px;
}

.mini-header_calendar {
    display: flex;
    width: 100%;
    margin: 0 auto;
    background-color: #D9D9D9;
    border-right: 4px solid #000;
    border-left: 4px solid #000;
    max-width: 1430px;
}

.mini-header_logo-background {
    width: 237px;
    height: 135px;
    background-color: white;
    border-bottom: 4px solid #000;
    border-right: 4px solid #000;
    z-index: 1;
}

.mini-header_logo {
    display: flex;
    flex-direction: column;
    width: 137px;
    height: 92px;
    background-color: white;
    margin: 22px 50px;
}

.mini-header_img {  
    width: 70.794px;
    height: 49.893px;
    margin: 0 auto;
    background-color: white;
}

.mini-header_logo-text {
    color: #000;
    font-family: 'Paytone One';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 13px auto 0;
}

.mini-header_menu {
    display: flex;
    background: linear-gradient(270deg, #F960D8 30.07%, #FA61AB 47.83%, #FC6270 66.04%, #FE6505 84.26%, #FE6417 102.47%);
    width: 100%;
    height: 83px;
    border-bottom: 4px solid #000;
}

.mini-header_list {
    width: 100%;
    justify-content: space-between;
    margin: 22px 60px;
    list-style-type: none;
    padding: 0;
    align-self: center;
}


@media screen and (width >= 744px ){
    .mini-header_list {
        display: flex !important;
    }
}

.mini-header_list-item {
    text-align: center;
    font-family: "Roboto Condensed";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.mini-header_list-link {
    color: #000;
}

.mini-header_list-link:hover {
    color: #DBFE01
}

.mini-header_list-link:active {
    background-color: white;
    color: #DBFE01
}

.mini-header_list-item:nth-child(5) {
    margin-right: 0px;
}

.mini-header_list-item:last-child {
    display: none;
}

.mini-header_burger {
    display: none;
    padding: 0;
    margin: 0;
    border: 0;
    width: 24px;
    height: 24px;
    margin: 3px 10px 0px 0px;
}


.mini-header_burger-img {
    display: none;
    width: 100%;
    height: 100%;
    background-color: #F860E8;
}


.mini-header_burger-img.burger-active {
    display: flex;
}

.mini-header_list.burger-visible {
    right: 4px;
}

@media screen and (width < 1440px ) and (width >= 744px){
    .mini-header_logo-background {
        width: 154px;
        height: 88px;
    }

    .mini-header_logo {
        width: 81px;
        height: 61px;
        margin: 17px 41px 10px 32px;
    }

    .mini-header_list-item {
        font-size: 20px;
        margin-right: 12px;
    }

    .mini-header_img {  
        width: 42px;
        height: 33px;
    }

    .mini-header_logo-text {
        font-size: 12px;
        margin-top: 9px;
    }
    
    .mini-header_menu {
        display: flex;
        height: 63px;
    }

    .mini-header_list {
        margin: 20px 10px;
    }
}

@media screen and (width < 744px){
    .mini-header {
        border-left: none;
        border-right: none;
    }

    .mini-header_menu {
        height: 33px;
        justify-content: end;
    }

    .mini-header_logo-background {
        display: none;
    }

    .mini-header_list {
        display: flex;
        position: fixed;
        right: -400px;
        top: 33px;
        background: linear-gradient(180deg, #F860E8 0%, #FB6279 50%, #FE6508 100%);
        width: 315px;
        height: 500px;
        flex-direction: column;
        z-index: 100;
        justify-content: center;
        gap: 40px;
        margin: 0;
        border-left: 4px solid #000;
        border-bottom: 4px solid #000;
        transition: right 0.5s;
    }

    .mini-header_list-item {
        font-size: 24px;
    }

    .mini-header_list-item:nth-child(5) {
        display: none;
    }
    
    .mini-header_list-item:last-child {
        display: block;
    }

    .mini-header_burger {
        display: flex;
        width: 24px;
        height: 24px;
        position: relative;
    }
}

