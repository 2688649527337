.gallery-personal-page {
    background-color: var(--color-dark);
    color: #fff;
    font-family: "Roboto";
    font-size: 24px;
    font-weight: 600;

    padding: 5px 50px 70px 50px;
}

.gallery-personal-page__top {
    display: flex;
    align-items: center;
    
    justify-content: space-around;
    padding: 0 40px;
}

.open-popup {
    color: #fff;
}

.gallery-personal-page__social-network {
    position: relative;
    z-index: 1;

    display: flex;
    flex-wrap: wrap;
    gap: 25px;

    justify-content: flex-end;
}

// hover
.gallery-personal-page__social-network a:hover svg path{
    fill: #DBFE01;
}

// active
.gallery-personal-page__social-network a:active svg path {
    fill: #F760E1;
}



// Увеличение фото при клике на фото
.popup__gallery-items {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(0, 0, 0, .6);
    justify-content: center;
    align-items: center;
}

.popup__gallery-relative {
    position: relative;
}

.popup__gallery-img {
    min-width: 20em;
    min-height: 20em;
    max-width: 75vw;
    max-height: 75vh;
    margin: 0 auto;
}

.popup__gallery-close {
    margin: 0;
    position: absolute;
    right: -40px;
    top: -40px;
    width: 32px;
    height: 32px;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    background-image: url(../img/close.svg);
    background-repeat: no-repeat;
}

@media screen and (width < 744px){
    .popup__gallery-img {
        min-width: 10em;
        min-height: 10em;
    }
}


@media (max-width: 575px) {
    .gallery-personal-page__top{
        padding-top: 100px;
        gap: 25px;
    }

}