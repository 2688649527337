*{box-sizing: border-box;}

.container {
    margin: 0 auto;
    background-color: #2A2929;
    border-right: 4px solid #000;
    border-left: 4px solid #000;
    border-bottom: 4px solid #000;
    max-width: 1430px;
}

/* page */
.page-passwordCompleted {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px auto ;
}

.page_logo-passwordCompleted {
    width: 246px;
    height: 176px;
    margin: 31px auto 0;
}

.page_title-passwordCompleted {
    color: #FFF;
    text-align: center;
    font-family: "Paytone One";
    font-size: 128px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.title_pink {
    color: #F860ED;
}

.title_green {
    color: #DBFE01;
}

@media screen and (width < 1440px){

    .page-passwordCompleted {
        margin: -2px auto 0;
    }

    .page_title-passwordCompleted {
        font-size: 64px;
        width: 451px;
        height: 81px;
        margin: 25px auto 0px;
    }

    .page_logo-passwordCompleted {
        width: 220px;
        height: 142px;
        margin-top: 17px;
    }
}

@media screen and (width < 744px){


    .page_logo-passwordCompleted {
        width: 102px;
        height: 69px;
        margin-top: 32px;

    }

    .page_title-passwordCompleted {
        font-size: 28px;
        width: 269px;
        margin: 5px auto 0px;
        height: 100%;
    }
}

/* account */
.account-passwordCompleted {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: 21px auto 290px;
}

.account_title-passwordCompleted {
    color: #FFF;
    text-align: center;
    font-family: 'Roboto';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 auto 30px;
}

.account_password-passwordCompleted {
    display: flex;
    margin: 86px auto 0;
}

.account_image-passwordCompleted {
    width: 44px;
    height: 55px;
}

.account_text-passwordCompleted {
    color: #FFF;
    text-align: center;
    font-family: "Roboto";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-self: end;
}

@media screen and (width < 1440px){
    .account-passwordCompleted {
        width: 438px;
        margin: 118px auto 360px;
    }

    .account_title-passwordCompleted {
        font-size: 32px;
        width: 310px;
        margin: 0 auto 231px;
    }

    .account_password-passwordCompleted {
        display: flex;
        margin: 0 auto;
    }

    .account_image-passwordCompleted {
        width: 35px;
        height: 38px;
    }

    .account_text-passwordCompleted {
        font-size: 24px;
    }

}

@media screen and (width < 744px){
    .account-passwordCompleted {
        width: 270px;
        margin: 102px auto 250px;
    }

    .account_title-passwordCompleted {
        font-size: 24px;
        width: 233px;
        margin: 0 auto 158px;
    }

    .account_image-passwordCompleted {
        width: 22px;
        height: 27px;
    }

    .account_text-passwordCompleted {
        font-size: 20px;
    }

}
