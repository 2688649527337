.card-max {
    display: flex;
    flex-wrap: wrap;
    border: solid 4px #000;
    width: 711px;
    height: 577px;
    padding: 40px 40px;
    align-items: center;
    cursor: pointer;
    &:hover {
        background: #F4E0F2;
        transition: ease-in 0.33s;
    }
}

    
    
.image-block {
    width: 334px;
    height: 467px;
    flex: 0 0 334px;
    overflow: hidden;
}

.image-block img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}

.article-block {
    width: 320px;
    height: 500px;
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
}

.article {
    text-decoration: none;
    position: relative;
    color: #000;
    display: block;
    width: 100%;
    height: 100%;
}
.article-block h1 {
    background: #F860E6;
    z-index: 5;
    position: absolute;
    width: 220px;
    top: -40px;
    right: 90px;
    font-family: "Roboto Condensed";
    font-weight: 700;
    font-size: 18px;
  }
  
  .article-block p {
    display: block;
    margin-top: 60px;
    
}

@media (max-width: 575.98px) {
    .card-max {
        display: flex;
        flex-wrap: wrap;
        border: solid 4px #000;
        width: 355px;
        height: 483px;
        padding: 10px 10px;
        align-items: center;
        
        
      }
      
      .image-block {
        width: 160px;
        height: 240px;
        flex: 0 0 160px;
        overflow: hidden;
      }
      
      .image-block img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      
      .article-block{
        position: relative;
        width: 160px;
        height: 240px;
        flex: 1;
        padding: 20px;
        box-sizing: border-box;
      }

      .article-block h1 {
        right: 10px;
      }
}