*{box-sizing: border-box;}

.container {
    margin: 0 auto;
    background-color: #2A2929;
    border-right: 4px solid #000;
    border-left: 4px solid #000;
    border-bottom: 4px solid #000;
    max-width: 1430px;

}


/* page */
.page-404 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto 291px;
}

.page_title-404 {
    color: #FF5FCF;
    font-family: "Rubik Glitch";
    font-size: 300px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 77px auto 0;
}

.title_green-404 {
    color: #DBFE01;
}

.page_inccorect-404 {
    position: relative;
    top: -30px;
    color: #FFF;
    font-family: 'Roboto';
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px auto;
}

.page_help-404 {
    margin: 35px auto 0;
    width: 851px;
    color: #FFF;
    text-align: center;
    font-family: 'Roboto';
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.page_help-link-404 {
    color: white;
    text-decoration-line: underline;
    cursor: pointer;
}

@media screen and (width < 1440px){
    .page-404 {
        margin: -2px auto 527px;
    }

    .page_title-404 {
        font-size: 200px;
        margin-top: 150px;
    }
    
    .page_inccorect-404 {
        top: -20px;
        font-size: 24px;
    }
    
    .page_help-404 {
        margin: 39px auto 0;
        width: 569px;
        font-size: 24px;
    }
}

@media screen and (width < 744px){
    .page-404 {
        margin: 0px auto 434px;
    }

    .page_title-404 {
        font-size: 120px;
        margin-top: 79px;
    }
    
    .page_inccorect-404 {
        top: -10px;
        font-size: 16px;
    }
    
    .page_help-404 {
        margin: 30px auto 0;
        width: 304px;
        font-size: 16px;
    }
}
