.personal-info {
    position: relative;
    padding: 10px 0 100px 0;
    background-color: var(--color-grey);

    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;

    z-index: 0;
}

.personal-info__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.personal-info__img-wrap {
    position: relative;
}

.personal-info__img {
    max-width: 377px;
    object-fit: cover;

    border: 4px solid #000;
    padding: 54px 29px 54px 36px;
}

.personal-info__sub-aske {
    position: absolute;
    z-index: 2;
    bottom: 40px;
    left: -30px;
}

.personal-info__info {
    
    // display: flex;
    // flex-direction: column;
    // gap: 25px;

    max-width: 700px;
    min-width: 300px;
}

.personal-info__name {
    display: inline-block;
    padding-inline: 10px 30px;
    background: #7199FF;

    margin-bottom: 20px;

    font-family: "Roboto Condensed";
    font-size: 33px;
    font-weight: 700;
    text-transform: uppercase;
}

.personal-info__genre {
    margin-bottom: 40px;

    font-size: 24px;
    font-weight: 600;
}

.personal-info__about-me {
    // display: block;
    // max-width: 700px;
    // min-width: 300px;
    line-height: 25px;

    word-break:break-all;

}

// тёмная клякса
.spot-dark {
    position: absolute;
    bottom: -450px;
    z-index: -1;
}

// белая клякса
.section-spot-white {
    position: relative;
}

.section-spot-white__qr-code {
    display: block;
    position: absolute;
    bottom: 15px;
    right: 80px;

    width: 130px;
    height: 130px;

    z-index: 1;
}

.section-spot-white__img-wrap {
    position: relative;
}

.section-spot-white__img-wrap:after {
    content: url(../img/spot-white.svg);

    position: absolute;
    z-index: 0;

    bottom: -139px;
    right: 0;
}


@media (max-width: 1279px) {
    .personal-info__container {
        gap: 30px;
        padding-bottom: 100px;
        padding-inline: 25px;
    }
}

@media (max-width: 767px) {
    .personal-info__container {
        flex-wrap: wrap;
        gap: 20px;
    }

    .personal-info__name {
        position: absolute;
        top: 88px;
        right: 19px;
    }

}