@include font-face("Paytone One", "PaytoneOne-Regular", 400);
@include font-face("Roboto Condensed", "RobotoCondensed-Bold", 700);
@include font-face("Roboto Flex", "RobotoFlex-Regular", 800);
@include font-face("Roboto", "Roboto-Bold", 700);
@include font-face("Roboto", "Roboto-Medium", 500);
@include font-face("Rubik", "Rubik-Bold", 700);
@include font-face("Rubik", "Rubik-Medium", 500);
@include font-face("Russo One", "RussoOne-Regular", 400);
@include font-face("Roboto", "Roboto-Black", 900);
@include font-face("Rubik Glitch", "RubikGlitch-Regular", 400);
