
.post__title {
  color: #030303;
  font-family: "Russo One";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 76.8px */
  text-transform: uppercase;
  display: block;
  width: 475px;
  position: relative;
  top: 0;
  left: 0;
  margin-top: 74px;
  

  &:after {
    content: url(../img/line.svg);
    z-index: -1;
    position: absolute;
    width: 524px;
    height: 100px;
    top: 0;
    left: -100px;
  }

  
}


.post__text {
  color: #000;
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 200px auto 50px;
  margin-top: 100px;
  width: 681.648px;
}

.post__text p {
  text-align: left;
  text-indent: 70px;
  display: block;
  margin-top: 25px;
}

.post__gallery {
  margin-top: 50px;
  margin-bottom: 130px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  justify-content: center;

  &-item {
    cursor: pointer;
    width: 280px;
    height: 280px;
  }

  &-img {
    filter: grayscale(100%);
    transition: 1s;
    width: 280px;
    height: 280px;

    &:hover {
      filter: grayscale(0);
    }
  }
}

.post__data {
  margin-top: 2px;
  font-family: "Russo One";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
