.pagination-pages__wrapper {
  padding: 150px;
}

.pagination-pages__list {
  margin: 0 auto;
}



.pagination__list-link {
  box-sizing: border-box;
  border: 3px solid rgb(0, 0, 0);
  background: rgb(255, 255, 255);
  color: #000;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 30px;
  padding: 15px 29px;
  
  &:hover {
   background: rgb(219, 254, 1);
  }

  &:active {
    background: rgb(228, 82, 1);
  }
}

.pagination-link-active {
    background: rgb(228, 82, 1);
}

.pagination-news {
    &:hover {
        background: rgb(228, 82, 1);
       }
     
       &:active {
        background: rgb(219, 254, 1);
         
       }
}

.pagination-news-active {
    background: rgb(219, 254, 1);
}


.--pagination-news-bg {
  
}