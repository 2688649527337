.info {
    position: relative;
    background-color: #2A2929;
    padding: 50px 0 150px 0;
    z-index: 0;

    color: #FFF;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
}

.btn-exit {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 1;

    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;
    justify-content: center;

    width: 66px;
    height: 66px;

    border: none;
    background-color: transparent;
    color: #fff;
    font-family: "Roboto";
    font-size: 20px;

    cursor: pointer;
}

.btn-exit:hover {
    border: 2px solid #DBFE01;
}

.btn-exit:active {
    border: 2px solid #FFF;
    color: #DBFE01;
    background-color: transparent;
}

.btn-exit:active svg path {
    fill: #DBFE01;
}


.info__container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 50px;
    // padding: 0 50px;
}

.info__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
}

.info__avatar {
    width: 377px;
    border: 2px solid #000;
    align-self: start;
}


.info__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 550px;
    min-width: 305px;
}

.info__list>li {
    border-bottom: 2px solid #DBFE01;
}

.info__list li:last-child {
    padding: 13px 19px;
    margin-top: 30px;
    border: 2px solid #DBFE01;
    line-height: 20px;
}


.info__bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
}

.info__buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 43px;
}

// кнопки
.--buttons-sizes {
    border: none;
    width: 215px;
    height: 72px;

    text-align: center;
    font-family: "Roboto";
    color: #000;
    font-size: 20px;
    font-weight: 600;

    cursor: pointer;
}

.info__btn-edit-info {
    background-color: #DBFE01;
}

.info__btn-add-social-network {
    background-color: #F760E1;
}

.info__btn-add-performances {
    background-color: #7199FF;
}

// hover
.--buttons-sizes:hover {
    background-color: #fff;
}

// active
.info__btn-edit-info:active {
    border: 4px solid #F760E1;
}

.info__btn-add-social-network:active {
    border: 4px solid #DBFE01;
}

.info__btn-add-performances:active {
    border: 4px solid #F760E1;
}

.info__links {
    display: flex;
    gap: 50px;
}

.info__qr-code {
    width: 145px;
    align-self: center;
}

.info__social-network {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.info__social-network a {
    width: 60px;
    height: 60px;
}

// hover
.info__social-network a:hover svg path {
    fill: #DBFE01;
}

// active
.info__social-network a:active svg path {
    fill: #F760E1;
}

// серая клякса
.spot-gray {
    position: absolute;
    bottom: -230px;
    z-index: -1;
}

// белая клякса
.spot-white {
    position: relative;
}

// кнопка добавления фото
.btn-download-photos {
    position: absolute;
    bottom: 0px;
    right: 40px;
    z-index: 1;

    font-family: "Roboto";
    font-size: 20px;
    color: #000;
    font-weight: 700;
    text-decoration-line: underline;
    text-transform: uppercase;

    cursor: pointer;
}

.btn-download-photos:hover {
    color: #E45201;
}

.btn-download-photos:active {
    color: #DBFE01;
}

.btn-download-photos::after {
    content: url(../img/spot-white.svg);

    position: absolute;
    z-index: -1;

    bottom: -200px;
    right: -41px;
}

@media (max-width: 1400px) {
    .info__container {
        display: block;
        // flex-direction: column;
        // flex-wrap: nowrap;
    }

    .info__top {
        margin-bottom: 50px;
    }

    .info__bottom {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }
}

@media (max-width: 575px) {
    .info__container {
        padding: 0 10px;
    }

    .info__top {}

    .info__bottom {}
}